import React from 'react';

import classNames from 'classnames';

import './CardGroup.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  columns?: number;
};

const CardGroup = ({ children, className = '', columns }: Props) => {
  const childCount = columns || React.Children.count(children);
  const classes = classNames({
    [`-cols-${childCount}`]: childCount,
    CardGroup: true,
    [className]: !!className,
  });

  return <div className={classes}>{children}</div>;
};

export default CardGroup;
