import { useCallback } from 'react';

type OnKeyDown = (e: React.KeyboardEvent) => void;

interface OnKeyDownOptions {
  isArrowKeysAllowed?: boolean;
  isDefaultPrevented?: boolean;
  nextFocusableElementId?: null | string;
}

const useOnKeyDown = (onKeyDown?: OnKeyDown, options: OnKeyDownOptions = {}) => {
  const { isArrowKeysAllowed = false, isDefaultPrevented = false, nextFocusableElementId = null } = options;

  return useCallback(
    (e: React.KeyboardEvent) => {
      if ([' ', 'Enter'].includes(e.key) || (isArrowKeysAllowed && ['ArrowLeft', 'ArrowRight'].includes(e.key))) {
        if (isDefaultPrevented) {
          e.preventDefault();
        }
        onKeyDown?.(e);
      }
      if (nextFocusableElementId && e.key === 'Tab') {
        const nextFocusableSection = document.getElementById(nextFocusableElementId);
        if (nextFocusableSection) {
          if (isDefaultPrevented) {
            e.preventDefault();
          }
          nextFocusableSection.focus();
        }
      }
    },
    [onKeyDown, isArrowKeysAllowed, isDefaultPrevented, nextFocusableElementId],
  );
};

/**
 * Browser adds default keyboard handler if we add click handler
 * But it doesn't work for bubbled keyboard events
 * Here we catch such events and trigger click
 */
export const imitateClickOnBubbledKeyEvent: React.KeyboardEventHandler<HTMLAnchorElement> = (event) => {
  const bubbled = event.currentTarget !== event.target;
  if (bubbled) {
    imitateClickOnKeyEvent(event);
  }
};

export const imitateClickOnKeyEvent: React.KeyboardEventHandler<HTMLElement> = (event) => {
  if ([' ', 'Enter'].includes(event.key)) {
    event.preventDefault();
    event.currentTarget?.click();
  }
};

export default useOnKeyDown;
