import { PropTypes } from 'prop-types';
import React from 'react';

import PlusImg from '@publicImages/svg/plus.svg';

import Svg from '@/components/Svg';

const Plus = ({ className, id, onClick, ...restProps }) => (
  <span className="Icon Plus">
    <Svg onClick={onClick} src={PlusImg} svgAttrs={id ? { id } : undefined} svgClassName={className} {...restProps} />
  </span>
);

Plus.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Plus.defaultProps = {
  ariaLabel: 'Plus',
  className: '',
  id: '',
  onClick: () => {},
};

export default Plus;
