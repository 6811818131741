import { useCallback, useEffect, useState } from 'react';

const useKeyboardFocus = (): boolean => {
  const [isKeyboardFocus, setIsKeyboardFocus] = useState(false);

  const handleMouseDown = useCallback(() => {
    setIsKeyboardFocus(false);
  }, []);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'Enter', 'Escape', 'Space', 'Tab'].includes(e.key)) {
      setIsKeyboardFocus(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown, { passive: true });
    window.addEventListener('keydown', handleKeyDown, { passive: true });

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return isKeyboardFocus;
};

export default useKeyboardFocus;
