import { isServerSide } from '@/helpers/isServerSide';

const getUserAgent = (): string => {
  if (isServerSide()) {
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    const { headers } = require('next/headers');
    return headers().get('user-agent')!;
  }
  return window.navigator.userAgent;
};

export default getUserAgent;
