import { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';

import { selectResources } from '@/ducks/common/selectors';
import { useAppSelector } from '@/store';

import { FormattedMessageContext } from './FormattedMessageContext';
import { getFormattedMessageString } from './generateMessage';

const selectGetterUIMessages = createSelector(
  selectResources,
  (resources) =>
    ({ defaultMessage, id, values = {} }: { defaultMessage: string; id: string; values?: Record<string, unknown> }) =>
      getFormattedMessageString(id, defaultMessage, resources?.[id], values),
);

/**
 * @deprecated use UIResource instead
 */
export const FormattedMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const resources = useAppSelector(selectResources);
  const formatMessage = useAppSelector(selectGetterUIMessages);

  const contextValues = useMemo(
    () => ({
      contextMessages: resources,
      formatMessage,
    }),
    [resources, formatMessage],
  );

  return <FormattedMessageContext.Provider value={contextValues}>{children}</FormattedMessageContext.Provider>;
};

export default FormattedMessageProvider;
