import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import Slider from 'react-slick';

import Arrow from '@/components/Icon/Arrow';
import GalleryRightArrow from '@/components/Icon/GalleryRightArrow';

import './Carousel.scss';

const defaultProps = {
  isVariantB: false,
  selectedImageIndex: 0,
  tabIndex: '',
  theme: '',
  updateGalleryIndex: () => {},
  wrapAround: false,
};

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  isVariantB: PropTypes.bool,
  selectedImageIndex: PropTypes.number,
  tabIndex: PropTypes.string,
  theme: PropTypes.string,
  updateGalleryIndex: PropTypes.func,
  wrapAround: PropTypes.bool,
};

const renderTabs = (index, selectedImageIndex) => {
  const isSelected = index === selectedImageIndex;
  return (
    <button
      aria-current={isSelected ? 'step' : null}
      aria-label={isSelected ? `You are currently on slide ${index + 1}` : `Go to slide ${index + 1}`}
      className="Carousel__tab"
      type="button"
    >
      <span tabIndex="-1" />
    </button>
  );
};

const RenderArrow = (props) => {
  const { className, direction, onClick } = props;

  let label = '';
  if (direction === 'left') {
    label = 'Next Slide';
  } else if (direction === 'right') {
    label = 'Previous Slide';
  }
  if (className.indexOf('disable') < 0) {
    return (
      <button
        aria-label={label}
        className={`Carousel__control Carousel__arrowBtn -${direction} ${className}`}
        onClick={onClick}
        type="button"
      >
        <span aria-label={label} role="button" tabIndex="-1">
          <Arrow />
        </span>
      </button>
    );
  }

  return null;
};

const RenderLeftArrow = (props) => {
  const { className, direction, onClick } = props;
  const label = 'Previous Slide';
  if (className.indexOf('disable') < 0) {
    return (
      <button
        aria-label={label}
        className={`Carousel__control Carousel__arrowBtn -${direction} ${className}`}
        onClick={onClick}
        type="button"
      >
        <span aria-label={label} role="button" tabIndex="-1">
          <GalleryRightArrow />
        </span>
      </button>
    );
  }
  return null;
};

const RenderRightArrow = (props) => {
  const { className, direction, onClick } = props;
  const label = 'Next Slide';
  if (className.indexOf('disable') < 0) {
    return (
      <button
        aria-label={label}
        className={`Carousel__control Carousel__arrowBtn -${direction} ${className}`}
        onClick={onClick}
        type="button"
      >
        <span aria-label={label} role="button" tabIndex="-1">
          <GalleryRightArrow />
        </span>
      </button>
    );
  }
  return null;
};

const slickSettings = {
  customPaging: renderTabs,
  dots: true,
  dotsClass: 'slick-dots Carousel__control',
  infinite: true,
  nextArrow: <RenderArrow direction="right" />,
  prevArrow: <RenderArrow direction="left" />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
};

const renderSlickSettings = (props, selectedImageIndex) => {
  const slickSettingsVariantB = {
    afterChange(index) {
      props.updateGalleryIndex(index);
    },
    customPaging: (index) => renderTabs(index, selectedImageIndex),
    dots: true,
    dotsClass: 'slick-dots Carousel__control',
    infinite: true,
    initialSlide: selectedImageIndex,
    nextArrow: <RenderRightArrow direction="right" {...props} />,
    prevArrow: <RenderLeftArrow direction="left" {...props} />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };
  return slickSettingsVariantB;
};

const Carousel = (props) => {
  const { children, isVariantB, selectedImageIndex, tabIndex, theme, wrapAround } = props;
  let carouselRef = useRef(null);
  useEffect(() => {
    carouselRef.current?.slickGoTo(selectedImageIndex);
  }, [selectedImageIndex]);

  if (isVariantB) {
    return (
      <Slider
        ref={carouselRef}
        {...renderSlickSettings(props, selectedImageIndex)}
        className={{
          [`-${theme}`]: !!theme,
          Carousel: true,
        }}
      >
        {children}
      </Slider>
    );
  }

  return (
    <Slider
      {...slickSettings}
      className={{
        [`-${theme}`]: !!theme,
        Carousel: true,
      }}
      tabIndex={tabIndex}
      wrapAround={wrapAround}
    >
      {children}
    </Slider>
  );
};

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

export default Carousel;
