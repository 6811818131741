import type { PathLeaf } from '@/ducks/routes/routes';

import { getPathname } from '@/ducks/routes/history';
import { routes } from '@/ducks/routes/routes';

const voyagePlannerRoutes = routes.planner;
type VoyagePlannerRoutesKey = keyof typeof voyagePlannerRoutes;

export const getRouterKey = (): VoyagePlannerRoutesKey => {
  const currentPathname = getPathname();

  return Object.keys(voyagePlannerRoutes).find((key) => {
    const { path: pagePathname } = (voyagePlannerRoutes[key as VoyagePlannerRoutesKey] || {}) as PathLeaf;
    return currentPathname === pagePathname;
  }) as VoyagePlannerRoutesKey;
};
