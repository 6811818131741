import React from 'react';

import TickImg from '@publicImages/svg/tick.svg';

import Svg from '@/components/Svg';

const Tick = () => (
  <span className="Icon Tick">
    <Svg src={TickImg} />
  </span>
);

export default Tick;
