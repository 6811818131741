import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import Tick from '@/components/Icon/Tick';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import './BoxCheckbox.scss';

const propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onBoxClick: PropTypes.func,
};

const defaultProps = {
  checked: false,
  name: null,
  onBoxClick: null,
};

const BoxCheckbox = ({ checked, name, onBoxClick }) => {
  const classes = classNames({
    BoxCheckbox: true,
    checked,
  });

  const onKeyDownTick = useOnKeyDown(onBoxClick, { isDefaultPrevented: true });

  return (
    <div className={classes} onClick={onBoxClick} onKeyDown={onKeyDownTick} role="button" tabIndex={0}>
      {checked && (
        <span>
          <Tick />
        </span>
      )}
      <span>{name}</span>
    </div>
  );
};

BoxCheckbox.propTypes = propTypes;
BoxCheckbox.defaultProps = defaultProps;

export default BoxCheckbox;
