/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';

import Minus from '@/components/Icon/Minus';
import Plus from '@/components/Icon/Plus';

import './Accordion.scss';

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  render() {
    const { active } = this.state;
    const {
      accordionItemDetails: { body, subtitle, title },
    } = this.props;
    const activeClass = active ? 'Active' : 'Inactive';

    return (
      <div className={`AccordionItem__Container ${activeClass}`}>
        <div className="DetailsContainer">
          <h3 className="Title">{title}</h3>
          <p className="SubTitle">{subtitle}</p>
          <p className="FoldingPanel">
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </p>
        </div>
        {activeClass === 'Inactive' ? (
          <div aria-label="Add" className="PlusIcon" type="button">
            <Plus id="PlusIcon" onClick={this.toggle} />
          </div>
        ) : (
          <div aria-label="Remove" className="MinusIcon" type="button">
            <Minus id="MinusIcon" onClick={this.toggle} />
          </div>
        )}
      </div>
    );
  }

  toggle() {
    const { active } = this.state;

    this.setState({
      active: !active,
    });
  }
}

AccordionItem.propTypes = {
  accordionItemDetails: PropTypes.shape({
    body: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }),
};

AccordionItem.defaultProps = {
  accordionItemDetails: {},
};

export default AccordionItem;
