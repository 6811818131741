import PropTypes from 'prop-types';
import React from 'react';

import { CSSTransition } from 'react-transition-group';

const propTypes = {
  direction: PropTypes.oneOf(['right', 'left', 'modal', 'bottom-right']),
};

const defaultProps = {
  direction: 'right',
};

class Slide extends React.PureComponent {
  render() {
    const { direction, ...props } = this.props;

    return <CSSTransition appear classNames={`Slide--${direction}`} mountOnEnter unmountOnExit {...props} />;
  }
}

Slide.propTypes = propTypes;
Slide.defaultProps = defaultProps;

export default Slide;
