import { useCallback, useMemo, useState } from 'react';

import usePermanentRef from '@/hooks/usePermanentRef';
import { type TOptional } from '@/types/common';

export type TOnChange = (isOpened: boolean) => void;

export type TOpenable = {
  change: (isOpened: boolean) => void;
  close: () => void;
  isOpened: boolean;
  open: () => void;
  toggle: () => void;
};

const useOpenable = (initialState?: boolean, onChange?: TOnChange): TOpenable => {
  const [isOpened, setIsOpened] = useState<boolean>(initialState ?? false);
  const onChangeRef = usePermanentRef<TOptional<TOnChange>>(onChange);

  const change = useCallback((value: boolean) => {
    setIsOpened(value);
    onChangeRef.current?.(value);
  }, []);

  const open = useCallback(() => change(true), []);

  const close = useCallback(() => change(false), []);

  const toggle = useCallback(
    () =>
      setIsOpened((state) => {
        onChangeRef.current?.(!state);
        return !state;
      }),
    [],
  );

  return useMemo(() => ({ change, close, isOpened, open, toggle }), [isOpened]);
};

export default useOpenable;
