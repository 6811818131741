import type { Function } from 'ts-toolbelt';

import classNames from 'classnames';

import Image from '@/components/Image';

import './Card.scss';

interface CardProps {
  children?: React.ReactNode;
  childrenClassName?: string;
  className?: string;
  clickHandler?: Function.Function;
  /** Renders the card with less vertical padding */
  condensed?: boolean;
  /** Conveniently pass only one icon */
  icon?: {
    alt: string;
    src: string;
  };
  /** Optional icons displayed at the top of the card (max 5) */
  icons?: {
    alt?: string;
    ariaHidden?: boolean;
    id?: string;
    src: string;
  }[];
  id?: string;
  /** Renders with a slightly bigger icon on desktop screens */
  largeIcon?: boolean;
  /** Renders the card with 0 bottom padding (can be useful depending on child elements) */
  noPadding?: boolean;
}

const Card = ({
  children,
  childrenClassName = '',
  className = '',
  clickHandler,
  condensed = false,
  icon,
  icons,
  id,
  largeIcon = false,
  noPadding = false,
}: CardProps) => {
  const classes = classNames({
    '-condensed': condensed,
    '-largeIcon': largeIcon,
    '-noPadding': noPadding,
    Card: true,
    [className]: !!className,
  });

  let iconArray;
  if (icons) {
    iconArray = icons;
  }
  if (icon) {
    iconArray = [{ ...icon, id: 'icon' }];
  }

  return (
    <div className={classes} id={id} onClick={clickHandler}>
      {iconArray && iconArray.length && (
        <div className="Card__icons">
          {iconArray.map((i) => (
            <Image
              alt={i.alt ?? ''}
              aria-hidden={i.ariaHidden}
              aspectRatio={1}
              className="Card__icon"
              key={i.id}
              src={i.src}
            />
          ))}
        </div>
      )}
      <div className={`Card__content ${childrenClassName}`}>{children}</div>
    </div>
  );
};

export default Card;
