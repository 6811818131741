import parseCmsTemplate from '@/ducks/common/resources/helpers/parseCmsTemplate';

/**
 * 'generateMessage' returns parts of a formatted resulting value
 * @param id - string - key of value in the state.common.resources
 * @param defaultMessage - string - default value if there is no value from state.common.resources by id
 * Note: 'id' and 'defaultMessage' may contain singular and plural checks: {number, plural, one {{singleEnding}} other {{pluralEnding}}}
 * @param contextMessage - string - contextMessages[id] - value by key in the context (state.common.resources)
 * @param values - object - collection of values which are passing in the message pattern with arguments
 * Note: A value must be supplied for every argument in the message pattern the instance was constructed with.
 */

const generateMessage = (id, defaultMessage, contextMessage, values) => {
  const message = contextMessage || defaultMessage || '';
  if (typeof message === 'object') {
    return message;
  }

  // It checks if contextMessage is a space, then an empty string will be returned
  if (!contextMessage?.trim() && contextMessage?.length > 0) {
    return [''];
  }

  if (!message.includes('{')) {
    return [message];
  }

  try {
    return parseCmsTemplate(message, values, 'throw');
  } catch (err) {
    // console.error('generateMessage:', err.message || `${err}`, 'id=', id, '; message=', message, '; values=', values);
    return parseCmsTemplate(defaultMessage, values, 'leave-as-is');
  }
};

// 'getFormattedMessageString' returns formatted string from context by key
export const getFormattedMessageString = (id, defaultMessage, contextMessage, values) => {
  const message = generateMessage(id, defaultMessage, contextMessage, values);
  if (typeof message === 'object') {
    return message;
  }
  return message ? message.join('') : '';
};
