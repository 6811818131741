import { useEffect } from 'react';

import difference from 'lodash/difference';

import subscribeOnDOMEvent from '@/helpers/subscribeOnDOMEvent';

import type { TKeyEventGroup } from '../types';

import getEventDisablers from '../helpers/getEventDisablers';
import tryHandleEscape from '../helpers/tryHandleEscape';
import tryHandleMoveFocus from '../helpers/tryHandleMoveFocus';
import { FocusTrapAttr } from '../types';

// Use attribute "data-focus-trap-disable" with comma separated list of TKeyEventGroup values to disable event handling

export const markFocusTrapRoot = (element: HTMLElement) => {
  if (!element.hasAttribute(FocusTrapAttr.ROOT)) element.setAttribute(FocusTrapAttr.ROOT, 'true');
};

export type TUseFocusTrapArgs = {
  disableEvents?: TKeyEventGroup[];
  element: HTMLElement | null;
  isOpened?: boolean;
  nodeSelector?: string;
  onClose?: () => void;
};

const useFocusTrap = ({ disableEvents, element, isOpened, nodeSelector, onClose }: TUseFocusTrapArgs) => {
  useEffect(() => {
    if (element) {
      markFocusTrapRoot(element);
      return subscribeOnDOMEvent(element, 'keydown', (event: KeyboardEvent) => {
        markFocusTrapRoot(element);
        const disablers = [...getEventDisablers(event, element), ...(disableEvents || [])];
        if (!disablers.includes('escape') && tryHandleEscape(event, onClose)) return;

        const keyGroups = difference<TKeyEventGroup>(['tab', 'x-arrows', 'y-arrows'], disablers);
        tryHandleMoveFocus(event, { container: element, keyGroups, nodeSelector });
      });
    }
  }, [disableEvents, element, isOpened, nodeSelector, onClose]);
};

export default useFocusTrap;
