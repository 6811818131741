const subscribeOnDOMEvent = <E extends Event>(
  target: EventTarget,
  type: string,
  listener: (event: E) => void,
  options?: AddEventListenerOptions | boolean,
) => {
  const handler = (event: Event) => listener(event as E);
  (target ?? document).addEventListener(type, handler, options);
  return () => {
    (target ?? document).removeEventListener(type, handler, options);
  };
};

export default subscribeOnDOMEvent;
