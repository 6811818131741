/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { CSSTransition } from 'react-transition-group';

class FadeIn extends React.PureComponent {
  focusOnFlyoutOpen = () => {
    const flyoutParent = document.getElementById('FlyoutParent');
    if (flyoutParent) {
      const firstFocus = document.getElementById('FocusElement');
      if (firstFocus) {
        firstFocus.focus();
      } else {
        flyoutParent.focus();
      }
    } else {
      const popoverContainer = document.getElementsByClassName('Popover__container')[0];
      if (popoverContainer) {
        const closeButton = popoverContainer.querySelector('.CloseBtn');
        if (closeButton) {
          closeButton.focus();
        } else {
          popoverContainer.focus();
        }
      }
    }
  };

  render() {
    return <CSSTransition classNames="fadeIn" onEntered={this.focusOnFlyoutOpen} timeout={300} {...this.props} />;
  }
}

export default FadeIn;
