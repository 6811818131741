import type { Object } from 'ts-toolbelt';

import cn from 'classnames';

import Close from '@publicImages/svg/close.svg?icon';
import Tick from '@publicImages/svg/tick.svg?icon';

import { isServerSide } from '@/helpers/isServerSide';
import { getSessionStorageValue } from '@/helpers/util/storage';

import './Notification.scss';

type Props = Object.Either<
  {
    children?: React.ReactNode;
    className?: string;
    error?: boolean;
    heading?: React.ReactNode;
    icon?: React.ReactNode;
    id?: string;
    messages?: {
      iconMessage?: string;
      id?: number;
      message?: React.ReactNode;
    }[];
    onDismiss?: () => void;
    promoContent?: React.ReactNode;
    success?: boolean;
    warning?: boolean;
  },
  'messages' | 'onDismiss'
>;

const Notification = ({
  children,
  className,
  error,
  heading,
  icon,
  id,
  messages = [],
  onDismiss,
  promoContent,
  success,
  warning,
}: Props) => {
  // Default icon for success notification
  if (icon == null && success) {
    icon = <Tick />;
  }

  // Notifications with messages cannot be dismissible
  const canDismiss = !messages.length && !!onDismiss;

  const invalidErrorMessage = !isServerSide() && getSessionStorageValue('paymentEmailInvalidError');

  const classes = cn('Notification', className, {
    '-dismiss': canDismiss,
    '-error': error && !warning,
    '-icon': !!icon,
    '-inValidEmailError': invalidErrorMessage,
    '-success': success,
    '-warning': warning,
  });

  return (
    <div className={classes}>
      <div className="Notification__wrapper" id={id} tabIndex={0}>
        <div className="Notification__header">
          {icon && (
            <div aria-hidden className="Notification__icon">
              {icon}
            </div>
          )}
        </div>
        <div className="Notification__main">
          {canDismiss && (
            <button aria-label="Close" className="Notification__close CloseBtn" onClick={onDismiss} type="button">
              <Close />
            </button>
          )}
          {promoContent && <div className="Notification__content PromoNotificationContent">{promoContent}</div>}
          <div className="Notification__content">
            {heading && (
              <p>
                <strong>{heading}</strong>
              </p>
            )}
            {children}
          </div>
        </div>
        {messages.slice(0, 2).map((item) => (
          <p className="Notification__message" key={item.id}>
            {item.iconMessage && (
              <span aria-hidden aria-label="" role="img">
                {item.iconMessage}
              </span>
            )}
            {item.message && <span>{item.message}</span>}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Notification;
