import React from 'react';

import GalleryRightArrowIcon from '@publicImages/svg/gallery-right.svg';

import Svg from '@/components/Svg';

const GalleryRightArrow = () => (
  <span className="Icon InfoIcon">
    <Svg src={GalleryRightArrowIcon} />
  </span>
);

export default GalleryRightArrow;
