// Updated FormCheck component
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import classNames from 'classnames';

import useKeyboardFocus from '@/hooks/useKeyboardFocus';

import FormControl from '../FormControl';

const propTypes = {
  align: PropTypes.oneOf(['top', 'center', 'bottom', 'underneath']),
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hiddenInput: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  onKeyDown: PropTypes.func, // Ensure prop type for onKeyDown
  optionOrder: PropTypes.number,
};

const defaultProps = {
  align: 'top',
  children: null,
  className: null,
  disabled: false,
  hiddenInput: false,
  icon: null,
  inline: false,
  invalid: false,
  label: null,
  labelProps: {},
};

const FormCheck = ({
  align,
  checked,
  children,
  className,
  disabled,
  hiddenInput,
  icon,
  id,
  inline,
  inputTabIndex,
  invalid,
  label,
  labelProps,
  onKeyDown,
  optionOrder = 0,
  role = 'checkbox',
  tabIndex,
  ...props
}) => {
  const isByKeyboard = useKeyboardFocus();

  const classes = classNames({
    [align]: align !== 'top',
    [className]: !!className,
    disabled: !!disabled,
    'form-check': true,
    'form-check-inline': inline,
    'hidden-input': hiddenInput,
    invalid: !!invalid,
  });

  const inputClasses = classNames('form-check-input', { checked, invalid, keyboardFocus: isByKeyboard });

  let isChecked = checked;
  let wrapperTabIndex = tabIndex ?? 0;
  let controlTabIndex = inputTabIndex ?? -1;
  let isAnySelected = props?.value || checked;

  // Enables native selection behaviour for radio groups
  const clickOnFirstFocus = useCallback((e) => {
    e.currentTarget?.click();
  }, []);

  let isRadio = role === 'radio';
  if (isRadio) {
    wrapperTabIndex = undefined;
    if (checked || (!isAnySelected && optionOrder === 0)) {
      controlTabIndex = undefined;
    } else {
      controlTabIndex = -1;
    }
    isChecked = undefined;
  }

  return (
    <div aria-label={label} className={classes} role={role} tabIndex={wrapperTabIndex}>
      <FormControl
        checked={isChecked}
        className={inputClasses}
        id={id}
        native
        onFocus={(isRadio && !isAnySelected && clickOnFirstFocus) || undefined}
        onKeyDown={onKeyDown}
        tabIndex={controlTabIndex}
        {...props}
        disabled={disabled}
      />
      <label {...labelProps} htmlFor={id}>
        {children}
      </label>
      {icon}
    </div>
  );
};

FormCheck.propTypes = propTypes;
FormCheck.defaultProps = defaultProps;

export default FormCheck;
