import PropTypes from 'prop-types';
import React from 'react';

import isEmpty from 'lodash/isEmpty';

import AccordionItem from './AccordionItem';

import './Accordion.scss';

const renderBlock = (item, key) => <AccordionItem accordionItemDetails={item} index={key} key={key} />;

const Accordion = ({ accordionItems }) => {
  if (!isEmpty(accordionItems)) {
    return <div>{accordionItems.map((item, index) => renderBlock(item, index))}</div>;
  }
  return null;
};

Accordion.propTypes = {
  accordionItems: PropTypes.shape([]),
};

Accordion.defaultProps = {
  accordionItems: [],
};

export default Accordion;
