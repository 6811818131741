import { forwardRef } from 'react';

import cn from 'classnames';

import FlyOutIcon from '@/components/Icon/FlyOut';

import IconButton from '../IconButton';

const CallToAction: typeof IconButton = forwardRef(({ children, className, ...props }, ref) => {
  return (
    <IconButton className={cn('btn-cta', className)} ref={ref} {...props}>
      {children}
      <FlyOutIcon />
    </IconButton>
  );
});

CallToAction.displayName = 'CallToAction';
export default CallToAction;
