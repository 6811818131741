import { subYears } from 'date-fns';

export const getMinAgeDate = (currentDate) => {
  // eslint-disable-line
  return subYears(currentDate, 18);
};

export const getMinSignupDate = (currentDate) => {
  // eslint-disable-line
  return subYears(currentDate, 13);
};
