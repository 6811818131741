import { useCallback, useEffect, useRef } from 'react';

import type { TOptional } from '@/types/common';

import getFirstFocusable from '@/ducks/a11y/helpers/getFirstFocusable';
import onlyOnClient from '@/helpers/ssrSafe/onlyOnClient';

const selectElement = (node: TOptional<TFocusableNode>, defaultNode?: HTMLElement): TOptional<HTMLElement> => {
  if (node instanceof HTMLElement) return node;
  if (typeof node === 'string') return onlyOnClient((arg: string) => document.querySelector<HTMLElement>(arg))(node);
  if (node !== false) return defaultNode;
};

type TFocusableNode = HTMLElement | boolean | string;

type UseModalStateFocus = {
  elementOnClose?: TFocusableNode;
  elementOnOpen?: TFocusableNode;
  isOpen: boolean;
  rootElement?: HTMLElement;
};

export const useModalStateFocus = ({ elementOnClose, elementOnOpen, isOpen, rootElement }: UseModalStateFocus) => {
  const elementBeforeFlyoutOpened = useRef<HTMLElement>();

  const onStateChange = useCallback(
    (state: boolean) => {
      if (state) selectElement(elementOnOpen, rootElement ? getFirstFocusable(rootElement) : undefined)?.focus();
      else selectElement(elementOnClose, elementBeforeFlyoutOpened.current)?.focus();
    },
    [elementOnClose, rootElement, elementOnOpen],
  );

  useEffect(() => {
    return () => {
      onStateChange(false);
      elementBeforeFlyoutOpened.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (isOpen) elementBeforeFlyoutOpened.current = document.activeElement as HTMLElement;
  }, [isOpen]);

  useEffect(() => onStateChange(isOpen), [isOpen, onStateChange]);

  return onStateChange;
};
