import type { TOptional } from '@/types/common';

import { TimeDigitName } from '../types';

export const formatDigit = (value: TOptional<number>): string =>
  `${value! > 0 ? Math.ceil(value!) : 0}`.padStart(2, '0');

const formatDigits = (
  values: TOptional<Record<TimeDigitName, number>>,
  preserveLeadingZeros?: TOptional<TimeDigitName>,
): Record<TimeDigitName, string> => {
  let canSkip = true;
  return [TimeDigitName.DAYS, TimeDigitName.HOURS, TimeDigitName.MINS, TimeDigitName.SECS].reduce(
    (acc, digitName) => {
      const value = values?.[digitName];
      const isRegular = !!value || digitName === preserveLeadingZeros;
      if (isRegular || !canSkip) {
        if (isRegular) canSkip = false;
        return { ...acc, [digitName]: formatDigit(value) };
      }
      return acc;
    },
    {} as Record<TimeDigitName, string>,
  );
};

export default formatDigits;
