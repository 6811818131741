import PropTypes from 'prop-types';
import React from 'react';

import { formatDate, parse } from '@/helpers/util/dateUtil';

const propTypes = {
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
};

const SailingDatesFormat = ({ endDate, startDate }) => {
  const startDateYear = parse(startDate).getFullYear();
  const endDateYear = parse(endDate).getFullYear();
  const startDateMonth = parse(startDate).getMonth();
  const endDateMonth = parse(endDate).getMonth();

  const sailingDates = (
    <>
      {startDateYear === endDateYear && startDateMonth === endDateMonth && (
        <span>{`${formatDate(startDate, 'dd')}-${formatDate(endDate, 'dd')} ${formatDate(
          startDate,
          'MMM, yyyy',
        )}`}</span>
      )}
      {startDateYear === endDateYear && startDateMonth !== endDateMonth && (
        <span>{`${formatDate(startDate, 'MMM dd')} - ${formatDate(endDate, 'MMM d, yyyy')}`}</span>
      )}
      {startDateYear !== endDateYear && (
        <span>{`${formatDate(startDate, 'MMM d, yyyy')} - ${formatDate(endDate, 'MMM d, yyyy')}`}</span>
      )}
    </>
  );
  return <>{sailingDates}</>;
};

SailingDatesFormat.propTypes = propTypes;

export default SailingDatesFormat;
