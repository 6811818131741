/**
 * Pill Button Component
 */

import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import './PillButton.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  isActive: false,
  onClick: () => {},
};

const PillButton = ({ children, isActive, onClick }) => (
  <button className={classNames('pill', { selectedPill: isActive })} onClick={onClick} type="button">
    {children}
  </button>
);

PillButton.propTypes = propTypes;
PillButton.defaultProps = defaultProps;

export default PillButton;
