import cn from 'classnames';

import Icon from '@publicImages/svg/chevron-down.svg?icon';

import classes from './Chevron.module.scss';

type Props = {
  position?: 'down' | 'left' | 'right' | 'up';
} & React.ComponentProps<typeof Icon>;

const Chevron = ({ className, position = 'down', ...props }: Props) => (
  <Icon className={cn('Chevron Caret', classes[position], className)} {...props} />
);

export default Chevron;
