import type { TOptional } from '@/types/common';

import { TimeDigitName } from '../types';

const makeDigits = (remainTime: number): TOptional<Record<TimeDigitName, number>> => {
  if (remainTime > 0) {
    let value = Math.ceil(remainTime / 1000);
    const seconds = value % 60;
    value = (value - seconds) / 60;
    const minutes = value % 60;
    value = (value - minutes) / 60;
    const hours = value % 24;
    value = (value - hours) / 24;
    return {
      [TimeDigitName.DAYS]: value,
      [TimeDigitName.HOURS]: hours,
      [TimeDigitName.MINS]: minutes,
      [TimeDigitName.SECS]: seconds,
    };
  }
};

export default makeDigits;
