import { type MutableRefObject, useEffect, useRef } from 'react';

const usePermanentRef = <A>(arg: A): MutableRefObject<A> => {
  const ref = useRef<A>(arg);

  useEffect(() => {
    ref.current = arg;
  }, [arg]);

  return ref;
};

export default usePermanentRef;
