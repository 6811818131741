import type { TOptional } from '@/types/common';

import { isServerSide } from '@/helpers/isServerSide';

const onlyOnClient =
  <A extends unknown[], R>(fn: (...args: A) => R) =>
  (...args: A): TOptional<R> => {
    if (!isServerSide()) return fn(...args);
  };

export default onlyOnClient;
