import { useState } from 'react';

import useOnKeyDown from '@/hooks/useOnKeyDown';
type Props = {
  children: React.ReactNode;
  className?: string;
  focusRef: React.RefObject<HTMLElement>;
  style?: React.ComponentProps<'div'>['style'];
};

const FocusButton = ({ children, className, focusRef, style }: Props) => {
  const [isFocused, setIsFocused] = useState(true);

  const toggleFocus = () => {
    if (isFocused) {
      focusRef.current?.focus();
    } else {
      focusRef.current?.blur();
    }
    setIsFocused(!isFocused);
  };
  const onKeyDownClick = useOnKeyDown(toggleFocus, { isDefaultPrevented: true });
  return (
    <div className={className} onClick={toggleFocus} onKeyDown={onKeyDownClick} role="button" style={style}>
      {children}
    </div>
  );
};

export default FocusButton;
