import cn from 'classnames';

import './ModalContent.scss';

type Props = {
  centered?: boolean;
  children: React.ReactNode;
  extendedClassName?: string;
};

const ModalContent = ({ centered, children, extendedClassName }: Props) => {
  return <div className={cn('ModalContent', centered && '-centered', extendedClassName)}>{children}</div>;
};

export default ModalContent;
