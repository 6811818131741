import React from 'react';

import ArrowImg from '@publicImages/svg/arrow-right.svg';

import Svg from '@/components/Svg';

const Arrow = () => (
  <span className="Icon Arrow">
    <Svg src={ArrowImg} />
  </span>
);

export default Arrow;
