import cn from 'classnames';

type Props = {
  children: React.ReactNode;
  modalClass?: string;
};

const ModalContentBody = ({ children, modalClass }: Props) => (
  <div className={cn('ModalContent__body', modalClass)}>
    <div className="ModalContent__bodyInner">{children}</div>
  </div>
);

export default ModalContentBody;
