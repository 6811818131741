import type { Function } from 'ts-toolbelt';

import { createContext, useContext } from 'react';

type FormattedMessageContext = {
  contextMessages: Record<string, unknown>;
  formatMessage: Function.Function;
};
/**
 * @deprecated
 */
export const FormattedMessageContext = createContext<FormattedMessageContext>({
  contextMessages: {},
  formatMessage: () => {},
});

/**
 * @deprecated
 * useUIResourcePool for multiple resource at once
 * useUIResource for single resource and for resource that requires parameters
 */
export const useFormattedMessageContext = () => {
  const context = useContext(FormattedMessageContext);

  return context;
};
