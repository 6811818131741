import cn from 'classnames';

import spinner from '@publicImages/spinner.gif';

import styles from './Spinner.module.scss';

type TProps = {
  isOnBookingPage?: boolean;
  shouldFixed?: boolean;
};

const Spinner = ({ isOnBookingPage, shouldFixed }: TProps) => (
  <div className={cn(styles.root, { _fixed: shouldFixed, _white: isOnBookingPage })} id="Spinner">
    <img alt="Loading" src={spinner} />
  </div>
);

export default Spinner;
