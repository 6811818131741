// Use attribute "data-focus-trap-disable" with comma separated list of TKeyEventGroup values to disable event handling

import { FocusTrapAttr, type TKeyEventGroup } from '@/ducks/a11y/types';

const getEventDisablers = (event: Event, root: HTMLElement): TKeyEventGroup[] => {
  const node = (event.target as HTMLElement)?.closest<HTMLElement>(`[${FocusTrapAttr.DISABLE}]`);
  return (
    node && node !== root && root.contains(node) ? node.dataset.focusTrapDisable!.replace(/\s+/gi, '').split(/,/gi) : []
  ) as TKeyEventGroup[];
};

export default getEventDisablers;
