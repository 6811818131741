import { PropTypes } from 'prop-types';
import React from 'react';

import CloseImg from '@publicImages/svg/close.svg';

import Svg from '@/components/Svg';

const Close = ({ id, ...restProps }) => (
  <span className="Icon Close" id={id} {...restProps}>
    <Svg src={CloseImg} />
  </span>
);

Close.propTypes = {
  id: PropTypes.string,
};

Close.defaultProps = {
  id: 'close',
};

export default Close;
