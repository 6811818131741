import React from 'react';

import classNames from 'classnames';

type FormControlProps = {
  className?: string;
  component?: 'input' | 'select';
  getRef?: React.Ref<HTMLInputElement> & React.Ref<HTMLSelectElement>;
  native?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLSelectElement>;
  tabIndex?: number;
};

const FormControl: React.FC<FormControlProps> = ({
  className = '',
  component: Component = 'input',
  getRef,
  native = false,
  onKeyDown,
  tabIndex,
  ...props
}) => {
  const classes = classNames({
    [className]: !!className,
    'form-control': !native,
  });

  return <Component className={classes} onKeyDown={onKeyDown} ref={getRef} tabIndex={tabIndex} {...props} />;
};

export default FormControl;
