import type { KeyboardEvent as SyntheticKeyboardEvent } from 'react';

import type { TOptional } from '@/types/common';

const tryHandleEscape = (event: KeyboardEvent | SyntheticKeyboardEvent, onDone?: () => void): TOptional<boolean> => {
  const { key, keyCode } = event;
  const isEscapeKey = key === 'Escape' || keyCode === 27;
  if (isEscapeKey && onDone) {
    onDone();
    event.stopPropagation();
    return true;
  }
};

export default tryHandleEscape;
