import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  cols: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxlength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  className: null,
  cols: 0,
  defaultValue: null,
  disabled: null,
  error: false,
  id: undefined,
  label: null,
  maxlength: 256,
  name: undefined,
  onBlur: null,
  onFocus: null,
  onKeyUp: null,
  placeholder: '',
  readonly: false,
  required: null,
  rows: 0,
  value: null,
};

/**
 * Text Area
 * @section Forms
 */

class TextArea extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focussed: false,
      hasContent: props.defaultValue || props.value,
    };

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value && value !== prevProps.value) {
      // TODO: refactor this; most likely focused and has-content styling can be handled via CSS
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        focussed: false,
        hasContent: true,
      });
    }
  }

  onBlur(event) {
    const { onBlur } = this.props;

    this.setState({
      focussed: false,
      hasContent: !!event.target.value,
    });

    if (onBlur) {
      onBlur();
    }
  }

  onFocus(event) {
    const { onFocus } = this.props;

    this.setState({
      focussed: true,
      hasContent: !!event.target.value,
    });

    if (onFocus) {
      onFocus();
    }
  }

  onKeyUp(event) {
    const { onKeyUp } = this.props;

    this.setState({
      focussed: true,
      hasContent: !!event.target.value,
    });

    if (onKeyUp) {
      onKeyUp(event);
    }
  }

  render() {
    const { focussed, hasContent } = this.state;
    const {
      className,
      cols,
      disabled,
      error,
      id,
      label,
      maxlength,
      name,
      placeholder,
      readonly,
      required,
      rows,
      value,
    } = this.props;
    const classes = classNames({
      TextArea: true,
      active: hasContent,
      [className]: !!className,
      disabled,
      error: !!error,
      focus: focussed,
      'no-label': !label,
    });
    return (
      <div className={classes}>
        {label && (
          <label htmlFor={id}>
            {label}
            {required && '*'}
          </label>
        )}
        {
          <div className="TextArea__container">
            <textarea
              cols={cols}
              id={id}
              maxLength={maxlength}
              name={name}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              onKeyUp={this.onKeyUp}
              placeholder={placeholder}
              readOnly={readonly}
              rows={rows}
            >
              {value}
            </textarea>
          </div>
        }
      </div>
    );
  }
}

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;

export default TextArea;
