import { getRouterKey } from '@/ducks/routes/helpers';

import currencySymbolsList from '../../data/currencySymbolsList';
const getSymbolFromCurrenciesData = (currencyCode?: string, onlySymbol?: boolean) => {
  if (typeof currencyCode !== 'string') {
    return undefined;
  }
  const code = currencyCode.toUpperCase();
  if (!currencySymbolsList[code]) {
    return undefined;
  }

  const currencySymbol = currencySymbolsList[code];

  if (typeof currencySymbol === 'string') {
    return currencySymbol;
  }

  return onlySymbol || !currencySymbol?.[getRouterKey()] ? currencySymbol?.default : currencySymbol?.[getRouterKey()];
};

export default getSymbolFromCurrenciesData;
