import { PropTypes } from 'prop-types';
import React from 'react';

import MinusImg from '@publicImages/svg/minus.svg';

import Svg from '@/components/Svg';

const Minus = ({ id, onClick }) => (
  <span className="Icon Minus">
    <Svg onClick={onClick} src={MinusImg} svgAttrs={id ? { id } : undefined} />
  </span>
);

Minus.propTypes = {
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Minus.defaultProps = {
  ariaLabel: 'Minus',
  id: '',
  onClick: () => {},
};

export default Minus;
