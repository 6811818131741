import { forwardRef } from 'react';

import cn from 'classnames';

type Props = {
  border?: boolean;
  children: React.ReactNode;
  invert?: boolean;
  size?: 'lg' | 'md' | 'sm';
} & React.ComponentPropsWithoutRef<'button'>;

const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ border, children, className, invert, size = 'md', ...props }, ref) => {
    const classes = cn(className, 'btn-icon', {
      [`btn-${size}`]: size !== 'md',
      'btn-border': border,
      'btn-invert': invert,
    });
    return (
      <button className={classes} ref={ref} type="button" {...props}>
        {children}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
